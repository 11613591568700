import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const ContactPage = ({ data }) => (
  <Layout>
    <SEO
      title="The Kalb Report | Contact Us"
      keywords={[`kalb`, `kalb report`, `the kalb report`]}
    />
    <section className="small-page-heading">
      <div className="container">
        <h1>Contact Us</h1>
      </div>
    </section>
    <section className="about-2 section bg-gray newsletter-section" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <p>
              Have a question about The Kalb Report? Send us an email, and we'll
              get back to you soon!
            </p>
            <hr />
            <p>
              Sign up for our mailing list to be among the first to know about
              upcoming programs!
            </p>
            <p>
              <a href="http://eepurl.com/gtAKFj" target="_blank">
                Click here to sign up
              </a>
            </p>
          </div>
          <div className="col-lg-8">
            <form
              name="kalb-contact-form"
              method="post"
              className="mailchimp-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/message-received"
            >
              <input type="hidden" name="form-name" value="kalb-contact-form" />
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Your name"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <textarea
                  type="text"
                  name="message"
                  placeholder="Your message"
                  className="form-control"
                />
              </div>

              <div
                style={{ position: "absolute", left: -5000 }}
                aria-hidden="true"
              >
                <input type="text" name="bot-field" tabIndex="-1" />
              </div>
              <input
                type="submit"
                value="Send message"
                className="btn mailchimp-submit"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
